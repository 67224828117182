.evaluations-header {
  font-family: Gilroy-Light;
  font-weight: 600;
  font-size: 1.5vw;
  color: #1A1E25;
  height: 100px;
  display: flex;
  align-items: center;
}
.evaluations-content {
  font-family: Gilroy-Light;
}